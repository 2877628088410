import { useState, useEffect, useContext } from "react";
import {
  useGetOnePageOfTemplateQuery,
  useLazyGetOnePageOfTemplateQuery,
} from "../slices/templateApiSlice";
import Spinner from "./Spinner";
import { useUser } from "@clerk/clerk-react";

interface Article {
  url: string;
  title: string;
  author: string;
  abstract: string;
  unrelatedlink: string;
  pdfurl: string;
  arxiv_id: string;
  index: number;
  unrelatedFunc: (link: string) => void;
}

const HomeContent = ({}) => {
  const { user } = useUser();
  const [query, setQuery] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [results, setResults] = useState<any[]>([]);

  useEffect(() => {
    sessionStorage.setItem("activeTab", "Home");
  }, []);
  const handleSearch = async (searchQuery: string) => {
    setIsSearching(true);
    const level = "general"; // Set the level here
    const response = await fetch(
      "https://autoreader-backend.ed-aisys.com/api/search",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ input: searchQuery, level: level }),
      }
    );
    var data = await response.json();
    var papers = data.papers;
    for (var i = 0; i < papers.length; i++) {
      papers[i].isUnrelated = false;
      papers[i].pdfurl = papers[i].url.replace("abs", "pdf");
      papers[i].arxiv_id = papers[i].url.split("/").pop();
      const paper_id = papers[i].url.split("/").pop();
      papers[
        i
      ].unrelatedlink = `https://autoreader-backend.ed-aisys.com/api/unrelated?query=${searchQuery}&paperid=${paper_id}`;
    }
    setResults(data.papers); // Based on your comment, the results are in the "papers" field
    setIsSearching(false);
  };

  const handleSubscribe = async (searchQuery: string) => {
    if (searchQuery.trim().length === 0) {
      alert("Please enter a search term!");
      return;
    }
    if (!user) {
      alert("Please sign in to subscribe");
      return;
    }
    const email = user.primaryEmailAddress?.emailAddress;
    console.log("email", user.primaryEmailAddress?.emailAddress);
    console.log(email);
    const response = await fetch(
      "https://autoreader-backend.ed-aisys.com/api/subscribe/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_email: email, query: searchQuery }),
      }
    );
    console.log(email);
    console.log(searchQuery);
    alert(`Subscribed to "${query}!"`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch(query);
    }
  };

  const uploadUnrelatedInfo = async (unrelatedlink: string) => {
    // setIsSearching(true);
    const response = await fetch(unrelatedlink, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
    // var data = await response.json();
    // setIsSearching(false);
  };

  return (
    <div className="home-content">
      <h1 className="home-content__ai">
        SEMANTIC SEARCH IN TODAY'S ARXIV PAPERS
      </h1>
      <div className="home-content__input-wrapper">
        <input
          type="text"
          autoComplete="none"
          id="home-content__search-input-bar-id"
          className="home-content__search-input-bar"
          placeholder="Search the topic that you are interested"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <svg
          className="home-content__search-input-bar-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          onClick={() => handleSearch(query)}
        >
          <path
            fill="#ececec"
            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
          />
        </svg>
      </div>
      <button
        className="home-content__subscribe-btn"
        onClick={() => handleSubscribe(query)}
      >
        Subscribe to this term
      </button>
      <div className="home-content__grid-container">
        {isSearching ? (
          <Spinner />
        ) : (
          results.map((result, index) => (
            <div className="home-content__grid-cell" key={index}>
              <div className="home-content__image-section">
                <h2>arXiv {result.arxiv_id}</h2>
              </div>
              <div className="home-content__wrapper-div">
                <div className="home-content__article-section">
                  <h2 className="home-content__grid-cell-h2">
                    <a
                      href={result.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {result.title}
                    </a>
                  </h2>
                  <p className="home-content__grid-cell-author">
                    {result.author}
                  </p>
                  <p className="home-content__grid-cell-abs">
                    {result.abstract}
                  </p>
                </div>
                <div className="home-content__btn-container">
                  <button
                    className="home-content__grid-cell-unrelated-btn"
                    onClick={() => uploadUnrelatedInfo(result.unrelatedlink)}
                  >
                    Unrelate
                  </button>
                  <a
                    className="home-content__grid-cell-read-btn"
                    href={result.pdfurl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read PDF
                  </a>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default HomeContent;
