import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { P } from "@clerk/clerk-react/dist/controlComponents-CzpRUsyv";
import Spinner from "./Spinner";
const SubscriptionContent = () => {
  const { user } = useUser();
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<any[]>([]);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (user) {
      const email = user.primaryEmailAddress?.emailAddress;
      setLoading(true);
      if (email) {
        const fetchSubscriptions = async () => {
          try {
            const response = await fetch(
              `https://autoreader-backend.ed-aisys.com/api/subscribe/get?user_email=${encodeURIComponent(
                email
              )}`,
              {
                headers: {
                  accept: "application/json",
                },
              }
            );
            if (!response.ok) {
              throw new Error("Failed to fetch subscriptions");
            }
            const data = await response.json();
            console.log(data);
            setSubscriptions(data.subscriptions);
          } catch (error: any) {
            setSubscriptions([]);
            setError(error.message);
          } finally {
            setLoading(false);
          }
        };

        fetchSubscriptions();
      } else {
        setLoading(false);
        setError("User email not found");
      }
    }
  }, [user]);
  const handleDelete = (subscription: any) => {
    // Logic for deleting the subscription
    console.log("Delete subscription:", subscription);
    const email = user?.primaryEmailAddress?.emailAddress;
    const deleteSubscription = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://autoreader-backend.ed-aisys.com/api/subscribe/remove",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_email: email, query: subscription }),
          }
        );
        if (response.ok) {
          const newSubs = subscriptions.filter((sub) => sub !== subscription);
          setSubscriptions(newSubs);
        }
        // if (!response.ok) {
        //     throw new Error('Failed to delete subscription');
        // }
      } catch (error: any) {
        console.error(error.message);
        // alert('Failed to delete subscription');
      } finally {
        setLoading(false);
      }
    };

    deleteSubscription();
    // Refresh page
  };

  return (
    <div className="home-content">
      <h2 className="subscription__title">Subscription List</h2>
      {user ? (
        loading ? (
          <Spinner />
        ) : (
          <ul className="subscription__list">
            {subscriptions.length === 0 ? (
              <h3 className="subscription__Ask-for-login">
                You don't have any subscription yet
              </h3>
            ) : (
              subscriptions.map((sub, index) => (
                <li key={index} className="subscription__list-li">
                  <div className="subscription__list-li-wrapper">
                    <div className="subscription__list-li-h3">
                      <h3>{sub}</h3>
                      <div className="subscription__list-li-btn-container">
                        <button onClick={() => handleDelete(sub)}>
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            )}
          </ul>
        )
      ) : (
        <h3 className="subscription__Ask-for-login">
          Please Login to see your subscription list
        </h3>
      )}
    </div>
  );
};

export default SubscriptionContent;
