import React from "react";
import logo from "./logo.svg";
import { Outlet } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import "./assets/css/main.css";

function App() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default App;
