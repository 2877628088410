import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UseSelector } from "react-redux";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { userInfo } from "os";

const Nav = () => {
  const navigate = useNavigate();
  const { userInfoDetailed } = useSelector((state: RootState) => state.auth);
  const storedActiveTab = sessionStorage.getItem("activeTab");
  const [selectedNav, setSelectedNav] = useState(
    storedActiveTab ? storedActiveTab : "Home"
  );
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
  useEffect(() => {}, []);
  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    //Event is triggered by an anchor element
    event.preventDefault();
    const id = event.currentTarget.id;
    if (id === "home") {
      sessionStorage.setItem("activeTab", "Home");
      navigate("/");
    } else if (id === "subscription") {
      sessionStorage.setItem("activeTab", "Subscription");
      navigate("/subscription");
    } else {
      sessionStorage.setItem("activeTab", "Roadmap");
      navigate("/roadmap");
    }
  };

  return (
    <div className="sidebar">
      <div className="user-info util-padding-left-right">
        {/* <div className="user-info__container">
          <svg
            className="user-info__container__star"
            viewBox="0 0 1025 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5255"
            width="24"
            height="24"
          >
            <path
              d="M784.16441 645.6c-3.8 3.7-5.5 9-4.6 14.2L835.36441 985c1.8 10.3-6.4 18.7-15.8 18.7-2.5 0-5-0.6-7.5-1.9L520.16441 848.3c-2.3-1.2-4.9-1.8-7.5-1.8s-5.1 0.6-7.5 1.8l-292.1 153.5c-2.5 1.3-5 1.9-7.5 1.9-9.3 0-17.5-8.4-15.8-18.7L245.66441 659.8c0.9-5.2-0.8-10.5-4.6-14.2L4.86441 415.3C-4.63559 406 0.56441 389.9 13.66441 388l326.5-47.5c5.2-0.8 9.7-4 12-8.8l146-295.9c2.9-5.9 8.6-8.9 14.3-8.9s11.4 3 14.3 8.9l146 295.9c2.3 4.7 6.8 8 12 8.8L1011.66441 388c13.1 1.9 18.4 18 8.9 27.3L784.16441 645.6z"
              p-id="5256"
              fill="#F0CC27"
            ></path>
          </svg>
          <h3
            className="user-info__container__name"
            onClick={handleClickUserName}
          >
            {userInfoDetailed ? userInfoDetailed.user.nickName : "Guest"}
          </h3>
        </div> */}
        {/* <div className="user-info__progression"></div> */}
      </div>
      <nav className="side-nav">
        <ul className="side-nav__ul">
          <li
            className={
              selectedNav === "Home" ? "side-nav__li active" : "side-nav__li"
            }
            id="home"
            onClick={handleClick}
          >
            <a href="#" className="side-nav__li-link">
              <svg
                className="side-nav__ul__icon util-padding-left-right util-padding-top-bot-small"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="6495"
                width="28"
                height="28"
              >
                <path
                  d="M921.6 495.616 778.24 352.256 778.24 151.552c0-12.288-8.192-20.48-20.48-20.48l-114.688 0c-12.288 0-20.48 8.192-20.48 20.48l0 49.152-98.304-98.304c-4.096-4.096-8.192-4.096-12.288-4.096s-8.192 4.096-12.288 4.096l-397.312 397.312c-4.096 4.096-4.096 8.192-4.096 12.288 0 12.288 8.192 20.48 20.48 20.48 4.096 0 8.192-4.096 12.288-4.096l81.92-81.92 0 462.848c0 12.288 8.192 20.48 20.48 20.48l188.416 0 188.416 0 188.416 0c12.288 0 20.48-8.192 20.48-20.48L819.2 442.368l81.92 81.92c4.096 4.096 8.192 4.096 12.288 4.096 12.288 0 20.48-8.192 20.48-20.48C925.696 503.808 925.696 499.712 921.6 495.616zM663.552 172.032 737.28 172.032l0 143.36-73.728-73.728L663.552 172.032zM401.408 888.832 401.408 888.832 249.856 888.832l0-36.864 151.552 0L401.408 888.832zM585.728 888.832l-151.552 0 0-303.104 151.552 0L585.728 888.832zM774.144 888.832l-151.552 0 0-36.864 151.552 0L774.144 888.832zM774.144 811.008l-151.552 0 0-245.76c0-12.288-8.192-20.48-20.48-20.48l-188.416 0c-12.288 0-20.48 8.192-20.48 20.48l0 245.76L249.856 811.008 249.856 405.504l262.144-262.144 262.144 262.144L774.144 811.008z"
                  p-id="6496"
                ></path>
                <path
                  d="M532.48 757.76m-20.48 0a0.5 0.5 0 1 0 40.96 0 0.5 0.5 0 1 0-40.96 0Z"
                  p-id="6497"
                ></path>
              </svg>
              <span>Home</span>
            </a>
          </li>
          <li
            className={
              selectedNav === "Subscription"
                ? "side-nav__li active"
                : "side-nav__li"
            }
            id="subscription"
            onClick={handleClick}
          >
            <a href="#" className="side-nav__li-link">
              <svg
                className="side-nav__ul__icon util-padding-left-right util-padding-top-bot-small"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="8503"
                width="25"
                height="25"
              >
                <path
                  d="M186.181818 837.818182l325.818182 0 0 46.545455-325.818182 0 0-46.545455ZM837.818182 837.818182 837.818182 884.363636 930.909091 884.363636 930.909091 0 232.727273 0 232.727273 93.090909 279.272727 93.090909 279.272727 46.545455 884.363636 46.545455 884.363636 837.818182ZM186.181818 698.181818l512 0 0 46.545455-512 0 0-46.545455ZM93.090909 149.410909l0 864.814545C93.090909 1019.624727 97.373091 1024 102.679273 1024l678.958545 0C786.990545 1024 791.272727 1019.671273 791.272727 1014.225455L791.272727 149.410909C791.272727 144.011636 786.990545 139.636364 781.684364 139.636364L102.679273 139.636364C97.373091 139.636364 93.090909 143.965091 93.090909 149.410909zM139.636364 186.181818l605.090909 0 0 791.272727L139.636364 977.454545 139.636364 186.181818zM186.181818 279.272727l512 0 0 46.545455-512 0 0-46.545455ZM186.181818 418.909091l512 0 0 46.545455-512 0 0-46.545455ZM186.181818 558.545455l512 0 0 46.545455-512 0 0-46.545455Z"
                  p-id="8504"
                ></path>
              </svg>
              <span>Subscription</span>
            </a>
          </li>
          <li
            className={
              selectedNav === "Projects"
                ? "side-nav__li active"
                : "side-nav__li"
            }
            id="project"
            onClick={handleClick}
          >
            <a href="#" className="side-nav__li-link">
              <svg
                className="side-nav__ul__icon util-padding-left-right util-padding-top-bot-small"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="10459"
                width="25"
                height="25"
              >
                <path
                  d="M374.6 636.5c4.4 0 8.5-1.2 12.1-3.3l171.7-100c8-3.6 13.6-11.9 13.6-21.5 0-8.8-4.8-16.6-11.9-20.7l-167.8-97.8c-4.3-5-10.7-8.1-17.7-8.1-13.1 0-23.6 10.7-23.6 23.8v1.3l-0.3 0.2 0.4 199.8c-0.1 0.8-0.1 1.6-0.1 2.5 0 13.2 10.6 23.8 23.6 23.8z"
                  p-id="10460"
                ></path>
                <path
                  d="M64.7 586.3a32.2 32.1 0 1 0 64.4 0 32.2 32.1 0 1 0-64.4 0Z"
                  p-id="10461"
                ></path>
                <path
                  d="M960 398.3c0.1-1.6 0.2-3.2 0.2-4.8 0-35-28.5-63.3-63.6-63.3-11.7 0-22.7 3.2-32.2 8.7l-0.5-0.3-31.5 18.2v-64.7c-0.1-73.1-59.9-133-133.1-133H197.4c-73.1 0-133 59.8-133 133v165.8h0.2c0 17.7 14.4 32.1 32.2 32.1s32.2-14.4 32.2-32.1h0.2V287c0-35.2 28.8-64 64-64h510.2c35.2 0 64 28.8 64 64v448.9c0 35.2-28.8 64-64 64H193.3c-35.2 0-64-28.8-64-64v-21.4c0-17.7-14.4-32.1-32.2-32.1-17.8 0-32.2 14.4-32.2 32.1h-0.4v15.3c0 73.2 59.9 133 133 133h501.9c73.2 0 133-59.8 133-133v-64.1l33.1 19.1 0.1-0.1c9.2 5.1 19.8 8 31 8 35.1 0 63.6-28.4 63.6-63.3 0-1.6-0.1-3.2-0.2-4.8V398.3z m-63.6 205.1c-0.3 7.8-6.9 14.1-15 14.1-2.7 0-5.3-0.7-7.5-2l-41.5-23.7V430.1l40.9-23.2c2.3-1.5 5.1-2.3 8.1-2.3 8.3 0 15 6.6 15 14.6v184.2z"
                  p-id="10462"
                ></path>
              </svg>
              <span>Roadmap</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Nav;
