import Header from "../components/Header";
import { useState } from "react";
import Nav from "../components/Nav";
import RoadMap from "../components/RoadMap";

const RoadmapScreen = () => {
  const [aiBuilder, setAiBuilder] = useState<boolean>(false);
  const [aiBuilderStep, setAiBuilderStep] = useState<number>(1);
  const incrementHandler = () => {
    setAiBuilderStep(aiBuilderStep + 1);
  };
  const startBtnHandler = () => {
    const currentTarget = document.querySelector(
      ".ai-builder-background-black"
    ) as HTMLDivElement;
    currentTarget.classList.add("visible-div");
    currentTarget.classList.remove("hidden-div");
  };
  const templateBtnHandler = () => {
    const currentTarget = document.querySelector(
      ".template-builder-background-black"
    ) as HTMLDivElement;
    currentTarget.classList.add("visible-div");
    currentTarget.classList.remove("hidden-div");
  };
  const cancelHandler = () => {
    const currentTarget = document.querySelector(
      ".ai-builder-background-black"
    ) as HTMLDivElement;
    currentTarget.classList.add("hidden-div");
    currentTarget.classList.remove("visible-div");
    setAiBuilderStep(1);
    console.log("Cleared:" + aiBuilderStep);
  };

  const templateUnitHoverHandler = () => {};
  return (
    <>
      <Header />
      <div className="content">
        <Nav />
        <RoadMap />
      </div>
    </>
  );
};

export default RoadmapScreen;
