import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import HomeScreen from "./screens/HomeScreen";
import ProjectScreen from "./screens/ProjectScreen";
import LoginScreen from "./screens/LoginScreen";
import { ClerkProvider } from "@clerk/clerk-react";
import { dark, neobrutalism } from "@clerk/themes";

//Redux
import ReduxStore from "./store";
import { Provider } from "react-redux";
import MaterialScreen from "./screens/MaterialScreen";
import RoadmapScreen from "./screens/RoadmapScreen";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomeScreen />}></Route>
      <Route path="/projects" element={<ProjectScreen />}></Route>
      <Route path="/subscription" element={<MaterialScreen />}></Route>
      <Route path="/roadmap" element={<RoadmapScreen />}></Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const PUBLISHABLE_KEY =
  "pk_test_b2JsaWdpbmctZmlsbHktOTEuY2xlcmsuYWNjb3VudHMuZGV2JA";
root.render(
  <StrictMode>
    <Provider store={ReduxStore}>
      <ClerkProvider
        publishableKey={PUBLISHABLE_KEY}
        appearance={{ baseTheme: dark }}
      >
        <RouterProvider router={router} />
      </ClerkProvider>
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
