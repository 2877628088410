import { apiSlice } from "./apiSlice";
import { LOGIN_URL, USER_URL } from "../constants";

export const loginApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: LOGIN_URL,
        method: "POST",
        body: { ...data },
      }),
    }),
    getUserInfo: builder.query({
      query: (data) => ({
        url: `${USER_URL}/profile`,
        method: "GET",
        headers: { Authorization: `Bearer ${data}` },
      }),
    }),
  }),
});

export const { useLoginMutation, useLazyGetUserInfoQuery } = loginApiSlice;
