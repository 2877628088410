import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import { BASE_URL } from "../constants";
import { Root } from "react-dom/client";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  //   prepareHeaders: (headers, { getState }) => {
  //     headers.set("Access-Control-Allow-Origin", "*");
  //     console.log("Here");
  //     // const token = localStorage.userInfo;
  //     // console.log("Here1");
  //     // if (token) {
  //     //   headers.set("Authorization", `Bearer ${token}`);
  //     // }
  //     console.log(headers);
  //     return headers;
  //   },
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [],
  endpoints: (builder) => ({}),
});
