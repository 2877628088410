import React from "react";
import Header from "../components/Header";
import Nav from "../components/Nav";
import SubscriptionContent from "../components/SubscriptionContent";

const MaterialScreen = () => {
  return (
    <>
      <Header />
      <div className="content">
        <Nav />
        <SubscriptionContent />
      </div>
    </>
  );
};

export default MaterialScreen;
