import { useNavigate } from "react-router-dom";
import {
  SignedIn,
  SignedOut,
  SignIn,
  SignInButton,
  SignOutButton,
  UserButton,
} from "@clerk/clerk-react";
import { dark, neobrutalism } from "@clerk/themes";
import logo from "../assets/logo-bg.png";

const Header = () => {
  const navigate = useNavigate();
  const loginHandler = () => {
    navigate("/login");
  };
  const handleClick = () => {
    sessionStorage.setItem("activeTab", "Home");
  };
  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="AutoReader-logo" className="logo"></img>
      </div>
      <div className="logo-text-container">
        <h3 className="logo-text-container__text">AutoReader</h3>
      </div>
      <nav className="user-nav">
        {/* <div className="user-nav__icon-box" id="profile" onClick={loginHandler}>
          <svg
            className="user-nav__icon-box__icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="13499"
            width="24"
            height="24"
          >
            <path
              d="M516.4 517.4c125 0 226.7-101.7 226.7-226.7 0-18.5-15-33.4-33.4-33.4s-33.4 15-33.4 33.4c0 88.2-71.7 159.9-159.9 159.9s-159.9-71.7-159.9-159.9 71.7-159.9 159.9-159.9c22.7 0 44.6 4.6 65.1 13.8 16.8 7.5 36.6 0 44.1-16.9 7.5-16.8 0-36.6-16.9-44.1-29.2-13-60.2-19.6-92.4-19.6-125 0-226.7 101.7-226.7 226.7s101.8 226.7 226.8 226.7zM619.8 556.8H413c-155.8 0-282.5 126.7-282.5 282.5v55.5c0 18.5 15 33.4 33.4 33.4s33.4-15 33.4-33.4v-55.5c0-118.9 96.8-215.7 215.7-215.7h206.7c118.9 0 215.7 96.8 215.7 215.7v55.5c0 18.5 15 33.4 33.4 33.4 18.5 0 33.4-15 33.4-33.4v-55.5c0.1-155.8-126.6-282.5-282.4-282.5z"
              fill="#6F8DE1"
              p-id="13500"
            ></path>
            <path
              d="M674.7 190.9m-33.4 0a33.4 33.4 0 1 0 66.8 0 33.4 33.4 0 1 0-66.8 0Z"
              fill="#6F8DE1"
              p-id="13501"
            ></path>
          </svg>
        </div> */}
        <div className="header__login-div">
          <SignedOut>
            <SignInButton>
              <button className="header__login-btn">Login In</button>
            </SignInButton>
          </SignedOut>
          <SignedIn>
            {/* <SignOutButton>
              <button className="header__sign-out-btn">Sign Out</button>
            </SignOutButton> */}
            <UserButton>
              <button onClick={handleClick} className="header__login-btn">
                Sign Out
              </button>
            </UserButton>
          </SignedIn>
        </div>
      </nav>
    </header>
  );
};

export default Header;
