import Header from "../components/Header";
import { useState } from "react";
import Nav from "../components/Nav";
import AiContext from "../context/AiContext";
import { TemplateContextProvider } from "../context/TemplateContext";
import AiBuilder from "../components/AiBuilder";
import HomeContent from "../components/HomeContent";
import TemplateBuilder from "../components/TemplateBuilder";

interface Row {
  id: number;
  tmpName: string;
  tmpPreviewUrl: string;
  tmpCoverUrl: string;
  tmpDesc: string;
  videoWidth: number;
  videoHeight: number;
  frameRate: number;
  hasSubtitle: number;
  hasMaterial: number;
  createBy?: string;
  createTime?: string;
  updateBy?: string;
  updateTime?: string;
  properStyle: string;
  properDuration: string;
  properPlatform: string;
}

const HomeScreen = () => {
  const [aiBuilder, setAiBuilder] = useState<boolean>(false);
  const [aiBuilderStep, setAiBuilderStep] = useState<number>(1);
  const incrementHandler = () => {
    setAiBuilderStep(aiBuilderStep + 1);
  };
  const startBtnHandler = () => {
    const currentTarget = document.querySelector(
      ".ai-builder-background-black"
    ) as HTMLDivElement;
    currentTarget.classList.add("visible-div");
    currentTarget.classList.remove("hidden-div");
  };
  const templateBtnHandler = () => {
    const currentTarget = document.querySelector(
      ".template-builder-background-black"
    ) as HTMLDivElement;
    currentTarget.classList.add("visible-div");
    currentTarget.classList.remove("hidden-div");
  };
  const cancelHandler = () => {
    const currentTarget = document.querySelector(
      ".ai-builder-background-black"
    ) as HTMLDivElement;
    currentTarget.classList.add("hidden-div");
    currentTarget.classList.remove("visible-div");
    setAiBuilderStep(1);
    console.log("Cleared:" + aiBuilderStep);
  };

  const templateUnitHoverHandler = () => {};
  return (
    <>
      <Header />
      <div className="content">
        <Nav />
        <HomeContent />
      </div>
    </>
  );
};

export default HomeScreen;
