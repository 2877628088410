import React from "react";

const RoadMap = () => {
  return (
    <>
      <div className="home-content">
        <h1 className="roadmap__h1">About This Project</h1>
        <h2 className="roadmap__h2">Inspiration</h2>
        <p className="roadmap__p">
          In the fast-paced world of science and technology, staying up-to-date
          with the latest research is crucial. New papers on computer science is 
          about 1000 papers per day. It is impossible to read all of them, even 
          only read the titles or abstracts. This project aims to help researchers 
          and students to find the most relevant papers to their interests. 

          (Furthermore, they can use email subscription to follow up their research interests.)
        </p>
        <h2 className="roadmap__h2">What's next?</h2>
        <p className="roadmap__p">
          - Improving paper ranking. <br />
          - Adding more papers: recent 3-years' arXiv papers. <br />
          - Adding more features in Email (Layout, Style, Paper trending...)<br />
          - Improving the UI/UX of the website. <br />
          - Adding more sources for papers. (arXiv not only CS is on the way, Pubmed and bioarXiv)<br />
        </p>
      </div>
    </>
  );
};

export default RoadMap;
