import { apiSlice } from "./apiSlice";
import { DICT_URL } from "../constants";

export const dictApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStyles: builder.query({
      query: () => ({
        url: `${DICT_URL}/data/list`,
        method: "GET",
        params: { dictType: "video_style_us" },
      }),
    }),
  }),
});

export const { useGetStylesQuery } = dictApiSlice;
