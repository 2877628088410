import Header from "../components/Header";
import { useState, useEffect } from "react";
import Nav from "../components/Nav";
import AiContext from "../context/AiContext";
import AiBuilder from "../components/AiBuilder";
import TemplateBuilder from "../components/TemplateBuilder";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials, logout } from "../slices/authSlice";
import { useLoginMutation } from "../slices/loginApiSlice";
import { RootState } from "../store";
import axios from "axios";
import { BASE_URL } from "../constants";
import userEvent from "@testing-library/user-event";
import { useGetStylesQuery } from "../slices/dictApiSlice";

const ProjectScreen = () => {
  const [isBtnClicked, setIsBtnClicked] = useState<boolean>(false);
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const { data, isLoading: styleQueryLoading } = useGetStylesQuery({});

  const tokenHandler = async () => {
    console.log("Worked");
    console.log(data);
  };
  const showHandler = () => {
    console.log(auth);
  };
  const logoutHandler = () => {
    dispatch(logout({}));
  };
  return (
    <>
      <Header />
      <div className="content">
        <Nav />
        <button onClick={tokenHandler}>Get token</button>
        <button onClick={showHandler}>Show token</button>
        <button onClick={logoutHandler}>Logout</button>
        {/* {isBtnClicked && <p>{data}</p>} */}
      </div>
    </>
  );
};

export default ProjectScreen;
