import { createSlice } from "@reduxjs/toolkit";

const storedUserInfo: string | null = localStorage.getItem("userInfo");
const storedUserInfoDetailed: string | null =
  localStorage.getItem("userInfoDetailed");
const initialState = {
  userInfo: storedUserInfo ? JSON.parse(storedUserInfo) : null,
  userInfoDetailed: storedUserInfoDetailed
    ? JSON.parse(storedUserInfoDetailed)
    : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
    },
    setUserDetailedInformations: (state, action) => {
      state.userInfoDetailed = action.payload;
      localStorage.setItem("userInfoDetailed", JSON.stringify(action.payload));
    },
    logout: (state, action) => {
      state.userInfo = null;
      state.userInfoDetailed = null;
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userInfoDetailed");
    },
  },
});

export const { setCredentials, setUserDetailedInformations, logout } =
  authSlice.actions;
export default authSlice.reducer;
